.header {
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 4;
  @include media("<=phone") {
    height: 96px;
  }
  .logo {
    a {
      font-size: 1.2rem;
      letter-spacing: 0.5rem;
      color: $black;
      text-decoration: none;
      font-weight: 700;
    }
  }
  .nav {
    width: 25px;
    @include media("<=phone") {
      width: 20px;
    }
    span {
      margin-bottom: 0.3rem;
      background: $black;
      height: 2px;
      width: 100%;
      display: block;
    }
  }
}
